import type { Router } from 'vue-router'
import { isNavigationFailure } from 'vue-router'
import NProgress from 'nprogress'
import { useRouteStoreWidthOut } from '@/store/modules/route'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { storage } from '@/utils/Storage'
import { PageEnum } from '@/enums/pageEnum'
import 'nprogress/nprogress.css'
import { useAuth } from '@/composables/useAuth'

NProgress.configure({ parent: '#app' })

const AUTH_PATH = PageEnum.BASE_AUTH
const SHARE_PATH = PageEnum.BASE_SHARE
const AUTH_PATH_NAME = PageEnum.BASE_AUTH_NAME
const AUTH_CALLBACK = PageEnum.BASE_AUTH_CALLBACK

// no redirect whitelist
const whitePathList = [AUTH_PATH_NAME, AUTH_CALLBACK, SHARE_PATH]

/**
 * 路由守护
 * 实现方案：
 *  1:本企业的企微内打开应用：
 *      a:有权限的企微用户，进入后根据授权地址获取 user_id & open_id
 *      b:无权限的企微用户，进入后根据授权地址获取 user_id，此时为空，跳转至个人微信授权
 *  2:其他企业企微内打开应用：采用 #1 中的 b步骤
 *  3:公众号内打开应用：
 *      a:有应用权限的企微用户，采用 #1中的 a步骤
 *      b:无应用权限的用户，使用公众号授权地址，获取open_id 判断用户是否关注，没有关注提示关注，然后注册
 */
export function createRouterGuards(router: Router) {
    /**
     * to: 即将要进入的目标
     * from: 当前导航正要离开的路由
     */
    router.beforeEach(async (to, from, next) => {
        // 获取运行环境
        const env = import.meta.env.MODE

        NProgress.start()

        if (from.path === AUTH_PATH && to.name === PageEnum.ERROR_PAGE_NAME) {
            next(PageEnum.BASE_HOME)
            return
        }

        // Whitelist can be directly entered
        if (whitePathList.includes(to.name as PageEnum)) {
            next()
            return
        }

        const token = storage.get(ACCESS_TOKEN)

        if (token) {
            next()
            return
        }

        if (['dev'].includes(env) || to.query?.debug) {
            if (to.path === AUTH_PATH) {
                next()
                return
            }

            next({
                path: AUTH_PATH,
                query: {
                    path: to.fullPath,
                },
            })
            return
        }

        // redirect login page
        useAuth().userOAuth('normal', to.fullPath)
    })

    // 进入某个路由之后触发的钩子
    router.afterEach((to, _, failure) => {
        // 设置每个页面的 title
        document.title = (to?.meta?.title as string) || document.title

        if (isNavigationFailure(failure)) {
            console.warn('failed navigation', failure)
        }

        const routeStore = useRouteStoreWidthOut()
        // 在这里设置需要缓存的组件名称
        const keepAliveComponents = routeStore.keepAliveComponents
        // 获取当前组件名
        const currentComName: any = to.matched.find(item => item.name === to.name)?.name

        // 如果 currentComName 且 keepAliveComponents 不包含 currentComName 且 即将要进入的路由 meta 属性里 keepAlive 为 true，则缓存该组件
        if (currentComName && !keepAliveComponents.includes(currentComName) && to.meta?.keepAlive) {
            // 需要缓存的组件
            keepAliveComponents.push(currentComName)
            // keepAlive 为 false 则不缓存
        }
        else if (!to.meta?.keepAlive) {
            // 不需要缓存的组件

            // 这里的作用一开始组件设置为缓存，之后又设置不缓存但是它还是存在 keepAliveComponents 数组中
            // keepAliveComponents 使用 findIndex 与 当前路由对比，如果存在则返回具体下标位置，不存在返回 -1
            const index = routeStore.keepAliveComponents.findIndex(name => name === currentComName)
            if (index !== -1) {
                // 通过返回具体下标位置删除 keepAliveComponents 数组中缓存的 元素
                keepAliveComponents.splice(index, 1)
            }
        }
        routeStore.setKeepAliveComponents(keepAliveComponents)
        NProgress.done()

        // 页面回到顶部
        window.scrollTo(0, 0)
    })

    router.onError((error) => {
        console.error(error, '路由错误')
    })
}
