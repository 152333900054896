/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-22 22:42:35
 * @LastEditors: Ben Ben.Xiao@cognizant.com
 * @LastEditTime: 2024-08-09 20:57:17
 * @FilePath: \digital-ocms-kacontent-mobile\src\router\modules.ts
 * @Description:
 */
import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layout/index.vue')

const routeModuleList: Array<RouteRecordRaw> = [
    {
        path: '/home',
        name: 'Home',
        redirect: '/home/index',
        component: Layout,
        meta: {
            title: '欧零学苑',
        },
        children: [
            {
                path: 'index',
                name: 'HomePage',
                meta: {
                    showFooter: true,
                    showHeader: false,
                },
                component: () => import('@/views/home/index.vue'),
            },
        ],
    },
    {
        path: '/article',
        name: 'Article',
        redirect: '/article/index',
        component: Layout,
        meta: {
            title: '欧零学苑',
        },
        children: [
            {
                path: 'index',
                name: 'ArticlePage',
                meta: {
                    keepAlive: true,
                    showFooter: true,
                },
                component: () => import('@/views/article/index.vue'),
            },
            {
                path: ':id',
                name: 'ArticleDetailPage',
                meta: {
                    showHeader: true,
                    showExtra: true,
                },
                component: () => import('@/views/article/detail.vue'),
            },
            {
                path: 'offline',
                name: 'ArticleOfflinePage',
                component: () => import('@/views/article/offline.vue'),
            },
        ],
    },
    {
        path: '/meeting',
        name: 'Meeting',
        redirect: '/meeting/index',
        component: Layout,
        meta: {
            title: '欧零学苑',
        },
        children: [
            {
                path: 'index',
                name: 'MeetingPage',
                meta: {
                    showHeader: true,
                },
                component: () => import('@/views/meeting/index.vue'),
            },
            {
                path: 'booking',
                name: 'MeetingBookingPage',
                meta: {
                    showHeader: true,
                },
                component: () => import('@/views/meeting/booking.vue'),
            },
            {
                path: ':code',
                name: 'MeetingDetailPage',
                meta: {
                    showHeader: true,
                },
                component: () => import('@/views/meeting/detail.vue'),
            },
            {
                path: 'push',
                name: 'MeetingPushPage',
                component: () => import('@/views/meeting/push.vue'),
            },
        ],
    },
    {
        path: '/my',
        name: 'My',
        redirect: '/my/index',
        component: Layout,
        meta: {
            title: '欧零学苑',
        },
        children: [
            {
                path: 'index',
                name: 'MyPage',
                meta: {
                    showFooter: true,
                },
                component: () => import('@/views/my/index.vue'),
            },
            {
                path: 'collect',
                name: 'MyCollectPage',
                meta: {
                    showFooter: true,
                },
                component: () => import('@/views/my/index.vue'),
            },
            {
                path: 'detail',
                name: 'MyInfoPage',
                meta: {
                    showHeader: true,
                },
                component: () => import('@/views/my/info.vue'),
            },
        ],
    },
    {
        path: '/survey/:code',
        name: 'SurveyPage',
        component: () => import('@/views/survey/index.vue'),
        meta: {
            title: '欧零学苑',
        },
    },
    {
        path: '/survey/report',
        name: 'SurveyReportPage',
        component: () => import('@/views/survey/report.vue'),
        meta: {
            title: '欧零学苑',
        },
    },
    {
        path: '/survey/complete',
        name: 'SurveyCompletePage',
        component: () => import('@/views/survey/complete.vue'),
        meta: {
            title: '欧零学苑',
        },
    },

    // 品牌练习
    {
        path: '/examine',
        name: 'Examine',
        redirect: '/examine/index',
        component: Layout,
        meta: {
            title: '欧零学苑',
        },
        children: [
            {
                path: 'index',
                name: 'ExaminePage',
                component: () => import('@/views/examine/index.vue'),
            },
            {
                path: 'choice',
                name: 'ExamineChoicePage',
                component: () => import('@/views/examine/choice.vue'),
            },
            {
                path: 'qrcode',
                name: 'ExamineQrCodePage',
                component: () => import('@/views/examine/qrcode.vue'),
            },
            {
                path: 'answer',
                name: 'ExamineAnswerPage',
                component: () => import('@/views/examine/answer.vue'),
            },
            {
                path: 'complete',
                name: 'ExamineCompletePage',
                component: () => import('@/views/examine/complete.vue'),
            },
            {
                path: 'record',
                name: 'ExamineRecordPage',
                component: () => import('@/views/examine/record.vue'),
            },
            {
                path: 'report',
                name: 'ExamineReportPage',
                component: () => import('@/views/examine/report.vue'),
            },
        ],
    },
]

export default routeModuleList
