<!--
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 14:53:34
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-08-15 22:43:49
 * @FilePath: \digital-ocms-kacontent-mobile\src\App.vue
 * @Description:
-->
<script setup lang="ts">
import { darken, lighten } from './utils'
import { isQyWeChat, isiOS } from './utils/is'
import { useAppStore } from './store/modules/app'
import { useRouteStore } from '@/store/modules/route'
import { useDesignSetting } from '@/hooks/setting/useDesignSetting'

const appStore = useAppStore()
const routeStore = useRouteStore()
const { getDarkMode, getAppTheme, getIsPageAnimate, getPageAnimateType } = useDesignSetting()

// 需要缓存的路由组件
const keepAliveComponents = computed(() => routeStore.keepAliveComponents)

function getThemeVars() {
    const appTheme = unref(getAppTheme)
    const darkenStr = darken(appTheme, 25)
    const lightenStr = lighten(appTheme, 10)

    return {
        actionSheetCancelTextColor: appTheme,
        actionSheetMaxHeight: '50%',
        buttonPrimaryBackground: appTheme,
        buttonPrimaryBorderColor: appTheme,
        radioCheckedIconColor: appTheme,
        sliderActiveBackground: appTheme,
        cascaderActiveColor: appTheme,
        checkboxCheckedIconColor: appTheme,
        numberKeyboardButtonBackground: appTheme,
        pickerLoadingIconColor: appTheme,
        pickerConfirmActionColor: appTheme,
        calendarRangeEdgeBackground: appTheme,
        calendarRangeMiddleColor: appTheme,
        calendarSelectedDayBackground: appTheme,
        stepperButtonRoundThemeColor: appTheme,
        switchOnBackground: appTheme,
        dialogConfirmButtonTextColor: appTheme,
        dropdownMenuOptionActiveColor: appTheme,
        dropdownMenuTitleActiveTextColor: appTheme,
        notifyPrimaryBackground: appTheme,
        circleColor: appTheme,
        noticeBarBackground: lightenStr,
        noticeBarTextColor: darkenStr,
        progressColor: appTheme,
        progressPivotBackground: appTheme,
        stepActiveColor: appTheme,
        stepFinishLineColor: appTheme,
        swipeIndicatorActiveBackground: appTheme,
        tagPrimaryColor: appTheme,
        navBarIconColor: '#fff',
        navBarTextColor: '#fff',
        navBarTitleTextColor: '#fff',
        navBarBackground: appTheme,
        paginationItemDefaultColor: appTheme,
        sidebarSelectedBorderColor: appTheme,
        tabsDefaultColor: appTheme,
        tabsBottomBarColor: appTheme,
        tabsNavBackground: '#fcfbfe',
        tabbarItemActiveColor: appTheme,
        treeSelectItemActiveColor: appTheme,
    }
}

const getTransitionName = computed(() => {
    return unref(getIsPageAnimate) ? unref(getPageAnimateType) : undefined
})

onMounted(() => {
    if (isiOS()) {
        const url = window.location.href.split('#')[0]
        appStore.setVisitUrl(url)

        console.log('[ocms.h5] App IOS WeChat', url)
    }

    if (isQyWeChat()) {
        console.log('[ocms.h5] App.onMounted Load jweixin-1.2.0')
        const script = document.createElement('script')
        script.src = 'https://res.wx.qq.com/open/js/jweixin-1.2.0.js'
        document.getElementsByTagName('head')[0].appendChild(script)
    }
    else {
        console.log('[ocms.h5] App.onMounted Load jweixin-1.3.0')
        const wxConfig = document.createElement('script')
        wxConfig.src = 'https://res.wx.qq.com/open/js/jweixin-1.3.0.js'
        document.getElementsByTagName('head')[0].appendChild(wxConfig)
    }
})
</script>

<template>
    <vanConfigProvider :theme="getDarkMode" :theme-vars="getThemeVars()">
        <routerView v-slot="{ Component }">
            <div class="main-page absolute bottom-0 top-0 w-full">
                <transition :name="getTransitionName" mode="out-in" appear>
                    <keep-alive v-if="keepAliveComponents" :include="keepAliveComponents">
                        <component :is="Component" />
                    </keep-alive>
                </transition>
            </div>
        </routerView>
    </vanConfigProvider>
</template>

<style lang="less">
  @import './styles/index.less';
</style>
