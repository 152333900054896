import { isObject } from './is/index'

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
    let key: string
    for (key in target) {
        src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key])
    }
    return src
}

/**
 * Sums the passed percentage to the R, G or B of a HEX color
 * @param {string} color The color to change
 * @param {number} amount The amount to change the color by
 * @returns {string} The processed part of the color
 */
function addLight(color: string, amount: number) {
    const cc = Number.parseInt(color, 16) + amount
    const c = cc > 255 ? 255 : cc
    return c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
}

/**
 * Darkens a HEX color given the passed percentage
 * @param {string} color The color to process
 * @param {number} amount The amount to change the color by
 * @returns {string} The HEX representation of the processed color
 */
export function darken(color: string, amount: number) {
    color = color.includes('#') ? color.substring(1, color.length) : color
    amount = Math.trunc((255 * amount) / 100)
    return `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(
    color.substring(2, 4),
    amount,
  )}${subtractLight(color.substring(4, 6), amount)}`
}

/**
 * Lightens a 6 char HEX color according to the passed percentage
 * @param {string} color The color to change
 * @param {number} amount The amount to change the color by
 * @returns {string} The processed color represented as HEX
 */
export function lighten(color: string, amount: number) {
    color = color.includes('#') ? color.substring(1, color.length) : color
    amount = Math.trunc((255 * amount) / 100)
    return `#${addLight(color.substring(0, 2), amount)}${addLight(
    color.substring(2, 4),
    amount,
  )}${addLight(color.substring(4, 6), amount)}`
}

/**
 * 判断是否 url
 */
const RegExp = /^(https?:\/\/[^\s$.?#].\S*)$/i
export function isUrl(url: string) {
    return RegExp.test(url)
}

/**
 * 一维数组转二维数组
 */
export function arrayTrans(arr: number[]): number[][] {
    const newArr: number[][] = []
    while (arr.length > 0) {
        newArr.push(arr.splice(0, 2))
    }
    return newArr
}

/**
 * Subtracts the indicated percentage to the R, G or B of a HEX color
 * @param {string} color The color to change
 * @param {number} amount The amount to change the color by
 * @returns {string} The processed part of the color
 */
function subtractLight(color: string, amount: number) {
    const cc = Number.parseInt(color, 16) - amount
    const c = cc < 0 ? 0 : cc
    return c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
}

export function hexToRgba(hex: string, opacity: number) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    opacity = opacity >= 0 && opacity <= 1 ? Number(opacity) : 1
    return result
        ? `rgba(${
        [Number.parseInt(result[1], 16), Number.parseInt(result[2], 16), Number.parseInt(result[3], 16), opacity].join(
          ',',
        )
        })`
        : hex
}

/**
 * 生成随机字符串
 * @param len 长度
 * @returns 随机字符串
 */
export function randomRange(len: number) {
    let returnStr = ''
    const charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < len; i++) {
        const index = Math.round(Math.random() * (charStr.length - 1))
        returnStr += charStr.substring(index, index + 1)
    }
    return returnStr
}
