/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 14:04:50
 * @LastEditors: Ben Ben.Xiao@cognizant.com
 * @LastEditTime: 2024-08-12 19:04:31
 * @FilePath: \digital-ocms-kacontent-mobile\src\store\modules\user.ts
 * @Description:
 */
import { defineStore } from 'pinia'
import { store } from '@/store'
import { createStorage } from '@/utils/Storage'
import { userAuth } from '@/api/modules/user'
import { ACCESS_TOKEN, CURRENT_USER } from '@/store/mutation-types'
import type { IUserState, UserInfo } from '@/typings/user'

const Storage = createStorage()

export const useUserStore = defineStore({
    id: 'app-user',
    state: (): IUserState => ({
        user: null,
        token: undefined,
    }),
    getters: {
        getUserInfo(): UserInfo {
            return this.user || Storage.get(CURRENT_USER, '') || {}
        },
        getToken(): string {
            return this.token || Storage.get(ACCESS_TOKEN, '')
        },
        isPublicUser(): boolean {
            let result = false
            const user = this.getUserInfo

            result
                = ['public', 'manager', 'salesclerk'].includes(user.role)
                || user?.olxy === '0'
                || (['hcp', 'hcp_manager'].includes(user.role) && user.status !== 'validated')

            return result
        },
        noFooterPermission(): boolean {
            let result = false
            const user = this.getUserInfo

            result
                = user?.olxy === '0'
                || user.role === 'public'
                || (['hcp', 'hcp_manager'].includes(user.role) && user.status !== 'validated')

            return result
        },
        isInternalUser(): boolean {
            return this.getUserInfo?.role === 'employee' && this.getUserInfo?.olxy === '1'
        },
        getUserTitle(): string {
            const roleTitles: { [key: string]: string } = {
                employee: '欧加隆内部员工',
                public: '大众',
                salesclerk: '零售药店店员',
                manager: '零售药店管理人员',
                hcp: '零售药店药师',
                hcp_manager: '零售药店药师经理',
            }

            return roleTitles[this.getUserInfo?.role || '']
        },
    },
    actions: {
        setToken(token: string | undefined) {
            this.token = token || ''
            Storage.set(ACCESS_TOKEN, token)
        },
        setUserInfo(info: UserInfo | null) {
            this.user = info
            Storage.set(CURRENT_USER, info)
        },

        /**
         * 用户授权
         * @param code 授权code
         */
        async userAuth(code: string, debug: boolean) {
            try {
                const { token, user } = await userAuth(code, debug)

                if (user) {
                    user.status = user.validateState
                    this.setToken(token)
                    this.setUserInfo(user)
                }
                return Promise.resolve(user)
            }
            catch (error) {
                return Promise.reject(error)
            }
        },
    },
})

// Need to be used outside the setup
export function useUserStoreWidthOut() {
    return useUserStore(store)
}
