/*
 * @Author: Ben (Ben.Xiao@cognizant.com)
 * @Date: 2022-12-15 17:38:01
 * @LastEditor: Ben (Ben.Xiao@cognizant.com)
 * @LastEditTime: 2024-08-13 20:32:52
 * @FilePath: \organon-china-h5\typings\enums\apis.ts
 * @Description: Api定义
 */
/**
 * @description: Api集合
 * @export
 * @enum {number}
 */
export enum Apis {
    // 获取授权token
    Auth = '/auth/token',

    /**
     * App 相关
     */

    // 获取App首页弹框提醒内容
    AppRemind = '/app/remind',
    // 获取APP全局配置信息
    AppConfig = '/app/config',

    /**
     * 微信相关
     */

    // 获取微信授权地址
    WeChatGetAuthUrl = '/wx/get-auth-url',
    // 获取企业微信用户信息
    WeChatGetUserInfo = '/wx/get-user-info',
    // 获取OpenId
    WeChatGetOpenId = '/wx/openid',
    // 获取授权token，根据openid
    WeChatGetToken = '/wx/token',
    // 获取微信分享签名信息
    WeChatGetJsSign = '/wx/jsapi',
    // 获取动态二维码
    WeChatQrCode = '/wx/qrcode',
    // 获取扫码状态
    WeChatSubscribe = '/auth/scan',
    // 创建调用jsapi时所需要的签名
    QwGetJsSign = '/wx/cp/jsapi',
    // 创建调用wx.agentConfig时所需要的签名
    QwGetAgentJsSign = '/wx/cp/agent_jsapi',
    // 获取微信授权地址
    QwGetAuthUrl = '/wx/cp/get-auth-url',
    // 获取企业微信用户信息
    QwGetUserInfo = '/wx/cp/get-user-info',

    /**
     * 文章相关
     */

    // 文章列表
    BlogsList = '/cms/list',
    // 文章详情
    BlogsInfo = '/cms/detail',
    // 文章列表
    BlogsUserLit = '/cms/user/list',
    // 文章详情 判断是否下架
    BlogsIsInvalid = '/cms/expired',
    // 发送文章到邮箱
    BlogsSendEmail = '/cms/send_email',
    // 收藏收藏 文章
    BlogsCollect = '/cms/collect',
    // 取消收藏 文章
    BlogsUnCollect = '/cms/uncollect',
    // 分享文章
    BlogsShare = '/cms/share',
    // 文章阅读时长
    BlogsReadTime = '/cms/read',
    // 文章阅读追溯
    BlogsTracing = '/cms/tracing',
    // 生成链路跟踪码
    BlogsCreateTracingCode = '/cms/tracing/create',
    // 记录下载素材时间
    BlogsDownloadTime = '/cms/download',
    // 获取文章相关标签数据
    BlogsTagList = '/cms/tag',

    /**
     * 会议相关
     */

    // 会议详情
    MeetingInfo = '/meeting',
    // 会议列表
    MeetingList = '/meeting/list',
    // 会议预约列表
    MeetingBookingList = '/meeting/booking/record',
    // 预约会议
    MeetingBooking = '/meeting/booking',
    // 会议行为记录
    MeetingBehavior = '/meeting/behavior',
    // 会议链路数据记录
    MeetingTracing = '/meeting/tracing',
    // 记录会议详情阅读时间
    MeetingReadTime = '/meeting/read',

    /**
     * 问卷相关
     */

    // 问卷信息
    SurveyInfo = '/survey',
    // 收集答案
    SurveyCollect = '/survey/collect',
    // 问卷报表
    SurveyReport = '/survey/report',
    // 获取用户姓名
    SurveyUserNames = '/survey/getUserName',

    /**
     * 品牌练习
     */

    // 获取品牌名称列表
    BrandExamineNameList = '/brand/exam/names',
    // 获取试题信息
    BrandExamineInfo = '/brand/exam/info/new',
    // 保存练习结果
    BrandExamineCollect = '/brand/exam/collect/new',
    // 品牌考试报表数据
    BrandExamReport = '/brand/exam/report',
    // 用户考试记录
    BrandExamineRecord = '/brand/exam/user/record/new',
}
