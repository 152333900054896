/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-22 22:42:35
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-08-03 09:47:54
 * @FilePath: \digital-ocms-kacontent-mobile\src\router\index.ts
 * @Description:
 */
import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { createRouterGuards } from './router-guards'
import routeModuleList from './modules'
import { AuthCallbackRoute, AuthRoute, ErrorPageRoute, RootRoute, ShareRoute } from '@/router/base'
import { useRouteStoreWidthOut } from '@/store/modules/route'

// 菜单

// 普通路由
export const constantRouter: RouteRecordRaw[] = [AuthRoute, AuthCallbackRoute, RootRoute, ShareRoute, ErrorPageRoute]

const routeStore = useRouteStoreWidthOut()

routeStore.setMenus(routeModuleList.filter(x => x.meta?.showFooter))
routeStore.setRouters(constantRouter.concat(routeModuleList))

const router = createRouter({
    history: createWebHistory(''),
    routes: constantRouter.concat(...routeModuleList),
    strict: true,
    scrollBehavior: () => ({ left: 0, top: 0 }),
})

export function setupRouter(app: App) {
    app.use(router)
    // 创建路由守卫
    createRouterGuards(router)
}

export default router
