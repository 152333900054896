/* eslint-disable ts/ban-ts-comment */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import axios from 'axios'
import qs from 'qs'
import { cloneDeep } from 'lodash-es'
import { AxiosCanceler } from './axiosCancel'
import type { CreateAxiosOptions, RequestOptions, Result, UploadFileParams } from './types'
import { isFunction } from '@/utils/is'

import { ContentTypeEnum, RequestEnum } from '@/enums/httpEnum'

export * from './axiosTransform'

/**
 * @description:  axios模块
 */
export class VAxios {
    private axiosInstance: AxiosInstance
    private options: CreateAxiosOptions

    constructor(options: CreateAxiosOptions) {
        this.options = options
        this.axiosInstance = axios.create(options)
        this.setupInterceptors()
    }

    getAxios(): AxiosInstance {
        return this.axiosInstance
    }

    /**
     * @description: 重新配置axios
     */
    configAxios(config: CreateAxiosOptions) {
        if (!this.axiosInstance) {
            return
        }
        this.createAxios(config)
    }

    /**
     * @description: 设置通用header
     */
    setHeader(headers: any): void {
        if (!this.axiosInstance) {
            return
        }
        Object.assign(this.axiosInstance.defaults.headers, headers)
    }

    /**
     * @description:   请求方法
     */
    request<T = any>(config: AxiosRequestConfig, options?: RequestOptions): Promise<T> {
        let conf: AxiosRequestConfig = cloneDeep(config)
        const transform = this.getTransform()

        const { requestOptions } = this.options

        const opt: RequestOptions = { ...requestOptions, ...options }

        const { beforeRequestHook, requestCatch, transformRequestData } = transform || {}
        if (beforeRequestHook && isFunction(beforeRequestHook)) {
            conf = beforeRequestHook(conf, opt)
        }

        // 这里重新 赋值成最新的配置
        // @ts-expect-error
        conf.requestOptions = opt
        // 支持 FormData
        conf = this.supportFormData(conf)

        return new Promise((resolve, reject) => {
            this.axiosInstance
                .request<any, AxiosResponse<Result>>(conf)
                .then((res: AxiosResponse<Result>) => {
                    // 请求是否被取消
                    const isCancel = axios.isCancel(res)
                    if (transformRequestData && isFunction(transformRequestData) && !isCancel) {
                        try {
                            const ret = transformRequestData(res, opt)
                            resolve(ret)
                        }
                        catch (err) {
                            reject(err || new Error('request error!'))
                        }
                        return
                    }
                    resolve(res as unknown as Promise<T>)
                })
                .catch((e: Error) => {
                    if (requestCatch && isFunction(requestCatch)) {
                        reject(requestCatch(e))
                        return
                    }
                    reject(e)
                })
        })
    }

    /**
     * @description:  创建axios实例
     */
    private createAxios(config: CreateAxiosOptions): void {
        this.axiosInstance = axios.create(config)
    }

    private getTransform() {
        const { transform } = this.options
        return transform
    }

    /**
     * @description:  文件上传
     */
    uploadFile<T = any>(config: AxiosRequestConfig, params: UploadFileParams) {
        const formData = new window.FormData()
        const customFilename = params.name || 'file'

        if (params.filename) {
            formData.append(customFilename, params.file, params.filename)
        }
        else {
            formData.append(customFilename, params.file)
        }

        if (params.data) {
            Object.keys(params.data).forEach((key) => {
                const value = params.data![key]
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                    return
                }

                formData.append(key, params.data![key])
            })
        }

        return this.axiosInstance.request<T>({
            method: 'POST',
            data: formData,
            headers: {
                'Content-type': ContentTypeEnum.FORM_DATA,
                'ignoreCancelToken': true,
            },
            ...config,
        })
    }

    // support form-data
    supportFormData(config: AxiosRequestConfig) {
        const headers = config.headers || this.options.headers
        const contentType = headers?.['Content-Type'] || headers?.['content-type']

        if (
            contentType !== ContentTypeEnum.FORM_URLENCODED
            || !Reflect.has(config, 'data')
            || config.method?.toUpperCase() === RequestEnum.GET
        ) {
            return config
        }

        return {
            ...config,
            data: qs.stringify(config.data, { arrayFormat: 'brackets' }),
        }
    }

    /**
     * @description: 拦截器配置
     */
    private setupInterceptors() {
        const transform = this.getTransform()
        if (!transform) {
            return
        }
        const {
            requestInterceptors,
            requestInterceptorsCatch,
            responseInterceptors,
            responseInterceptorsCatch,
        } = transform

        const axiosCanceler = new AxiosCanceler()

        // 请求拦截器配置处理
        // @ts-expect-error
        this.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
            const { headers: { ignoreCancelToken } = { ignoreCancelToken: false } } = config
            const ignoreCancel
        = ignoreCancelToken !== undefined
            ? ignoreCancelToken
            : this.options.requestOptions?.ignoreCancelToken

            !ignoreCancel && axiosCanceler.addPending(config)
            if (requestInterceptors && isFunction(requestInterceptors)) {
                config = requestInterceptors(config, this.options)
            }
            return config
        }, undefined)

        // 请求拦截器错误捕获
        requestInterceptorsCatch
        && isFunction(requestInterceptorsCatch)
        && this.axiosInstance.interceptors.request.use(undefined, requestInterceptorsCatch)

        // 响应结果拦截器处理
        this.axiosInstance.interceptors.response.use((res: AxiosResponse<any>) => {
            res && axiosCanceler.removePending(res.config)
            if (responseInterceptors && isFunction(responseInterceptors)) {
                res = responseInterceptors(res)
            }
            return res
        }, undefined)

        // 响应结果拦截器错误捕获
        responseInterceptorsCatch
        && isFunction(responseInterceptorsCatch)
        && this.axiosInstance.interceptors.response.use(undefined, responseInterceptorsCatch)
    }
}
