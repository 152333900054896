/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 14:04:50
 * @LastEditors: Ben Ben.Xiao@cognizant.com
 * @LastEditTime: 2024-08-09 22:24:34
 * @FilePath: \digital-ocms-kacontent-mobile\src\router\base.ts
 * @Description:
 */
import type { RouteRecordRaw } from 'vue-router'
import { PageEnum } from '@/enums/pageEnum'

const Layout = () => import('@/layout/index.vue')

// 404 on a page
export const ErrorPageRoute: RouteRecordRaw = {
    path: '/:path(.*)*',
    name: PageEnum.ERROR_PAGE_NAME,
    component: Layout,
    meta: {
        title: '欧零学苑',
        hiddenHeader: true,
        hideBreadcrumb: true,
    },
    children: [
        {
            path: '/:path(.*)*',
            name: 'ErrorPageSon',
            component: () => import('@/views/exception/404.vue'),
            meta: {
                title: '欧零学苑',
                hideBreadcrumb: true,
            },
        },
    ],
}

export const RootRoute: RouteRecordRaw = {
    path: '/',
    name: 'Root',
    redirect: PageEnum.BASE_HOME,
    meta: {
        title: '欧零学苑',
    },
}

export const AuthRoute: RouteRecordRaw = {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth/index.vue'),
    meta: {
        title: '欧零学苑',
    },
}

export const AuthCallbackRoute: RouteRecordRaw = {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: () => import('@/views/auth/callback.vue'),
    meta: {
        title: '欧零学苑',
    },
}

export const ShareRoute: RouteRecordRaw = {
    path: '/share/:id',
    name: 'Share',
    component: () => import('@/views/share/index.vue'),
    meta: {
        title: '欧零学苑',
    },
}
