/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 14:04:50
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-08-04 10:59:31
 * @FilePath: \digital-ocms-kacontent-mobile\src\store\modules\app.ts
 * @Description:
 */
import { defineStore } from 'pinia'
import { APP_CONFIG, APP_VISIT_URL } from '../mutation-types'
import { createStorage } from '@/utils/Storage'
import { store } from '@/store'
import { appConfig } from '@/api/modules/app'

export interface IAppState {
    config: any
    visitUrl: string
    emailPopup: boolean
}

const Storage = createStorage()

export const useAppStore = defineStore({
    id: 'app',
    state: (): IAppState => ({
        config: null,
        // ios微信用，记录访问时候页面url
        visitUrl: '',
        emailPopup: false,
    }),
    getters: {
        getConfig(): any {
            return this.config || Storage.get(APP_CONFIG) || []
        },
        getVisitUrl(): string {
            return this.visitUrl || Storage.get(APP_VISIT_URL)
        },
        getEmailPopupVisible(): boolean {
            return this.emailPopup || false
        },
    },
    actions: {
        setConfig(data: any): void {
            this.config = data
            Storage.set(APP_CONFIG, data)
        },

        setVisitUrl(url: string): void {
            this.visitUrl = url
            Storage.set(APP_VISIT_URL, url)
        },

        setEmailPopupVisible(val: boolean): void {
            this.emailPopup = val
        },

        async getAppConfig(code: string) {
            try {
                const response = await appConfig(code)
                this.setConfig(response)
                return Promise.resolve(response)
            }
            catch (error) {
                return Promise.reject(error)
            }
        },
    },
})

// Need to be used outside the setup
export function useAppWithOut() {
    return useAppStore(store)
}
