/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-30 14:45:55
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-07-30 14:53:19
 * @FilePath: \digital-ocms-kacontent-mobile\src\composables\useAuth.ts
 * @Description:
 */
import { randomRange } from '@/utils'
import { isQyWeChat } from '@/utils/is'
import { createStorage } from '@/utils/Storage'

export function useAuth() {
    /**
     * 用户授权
     * @param identity_mode 类型 normal授权 change修改角色
     * @param path 授权成功后跳转的页面地址
     */
    function userOAuth(identity_mode: string, path: string) {
        // 将路由保存到缓存中
        const state = randomRange(32)
        const storage = createStorage()
        // 将路径保存到本地缓存中
        storage.set(state, path)

        const request_mode = isQyWeChat() ? 'wecom' : 'wechat'
        // 上游授权地址
        const oauth_url = import.meta.env.VITE_OAUTH_BASE_URL
        const client_id = import.meta.env.VITE_OAUTH_CLIENT_ID
        // 授权成功回调地址
        const redirect_url = encodeURIComponent(import.meta.env.VITE_OAUTH_REDIRECT_URL || '')
        // 强制关注公众号后跳转的地址 这里需要取浏览器当前地址
        const subscribe_url = encodeURIComponent(`<a href='${location.href}'>请点击这里继续阅读</a>`)
        const query = `?client_id=${client_id}&identity_mode=${identity_mode}&subscribe_uri=${subscribe_url}&state=${state}&redirect_uri=${redirect_url}`
        const href = `${oauth_url}${query}&request_mode=${request_mode}&response_type=code&grant_type=authorization_code`
        window.location.href = href
    }
    return {
        userOAuth,
    }
}
