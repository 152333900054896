/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-24 07:41:17
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-08-02 17:49:33
 * @FilePath: \digital-ocms-kacontent-mobile\src\api\modules\app.ts
 * @Description:
 */
import { http } from '@/utils/http/axios'
import { Apis } from '@/enums/apis'
import { RequestEnum } from '@/enums/httpEnum'

/**
 * 获取app配置信息
 *
 * @param code 配置项对应的key
 * @returns 配置数据
 */
export async function appConfig(code: string) {
    return await http.request<any>({
        url: Apis.AppConfig,
        method: RequestEnum.GET,
        params: { code },
    }, {
        isShowErrorMessage: true,
    })
}

/**
 * 获取首页需要提醒的文章列表
 *
 * @returns 文章列表
 */
export async function appRemind() {
    return await http.request<any>({
        url: Apis.AppRemind,
        method: RequestEnum.GET,
    }, {
        isShowErrorMessage: true,
    })
}
